.paginator {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-bottom: 4vh;

    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    .button {
        display: flex;
        border-radius: 5px;
        background: var(--color-white);
        height: 40px;
        width: 40px;
    }

    .button_disabled {
        border: 1px solid var(--color-gray);
        cursor: not-allowed;
    }

    .button_enabled {
        border: 1.258px solid var(--color-border-gray);
        cursor: pointer;

        &:hover {
            background-color: var(--color-btn-hover);
        }
    }

    .arrow {
        filter: brightness(0) saturate(100%) invert(8%) sepia(27%) saturate(4920%) hue-rotate(195deg) brightness(94%) contrast(106%);
    }

    .rotate {
        transform: rotate(180deg);
    }
}