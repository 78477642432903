@import "../../../styles/breakpoints.scss";

.input {
	column-gap: 0.6rem;
	display: flex;
	gap: 12px;
	flex-direction: column;
	width: 100%;

	// input {
	// 	margin-top: 0.5rem;
	// }

	&_content { 
		display: flex;
		gap: 16px;
		align-items: center;
	}
}

textarea {
	font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	outline: none;

	&::-webkit-scrollbar-track {
		border-radius: 50px;
	}

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-secondary-light);
		border: solid 6px transparent;
		border-radius: 0.5rem;
	}
}

.input_container {
	display: flex;
	position: relative;
	width: 100%;

	.input_select {
		cursor: pointer;
		display: flex;
		width: 100%;
	}

	.input_select_blocked {
		display: flex;
		width: 100%;
		color: var(--color-border-gray);
		background: var(--color-select-blocked);
	}

	.input_searcher {
		display: flex;
		width: 100%;
	}

	.input_searcher_with_text {
		cursor: pointer;
		display: flex;
		width: 100%;
		background: var(--color-purple-dark);
		color: var(--color-white);
		font-weight: 600;
	}
}

.input_select_new {
	background: var(--color-white);
	display: flex;
	flex-direction: column;
	top: 60px;
	max-height: 200px;
	position: absolute;
	width: 100%;
	z-index: 1;

	input {
		color: var(--color-secondary-light);
		font-size: 14px;
		font-weight: 700;
		margin: 0;

		&:hover {
			background: var(--color-table-row-selected);
		}
	}
}

.select_container {
	background: var(--color-white);
	border: 1px solid var(--color-border-gray);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	top: 60px;
	max-height: 200px;
	overflow-y: overlay;
	padding: 2px;
	position: absolute;
	width: calc(100% - 5px);
	z-index: 1;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			align-items: center;
			cursor: pointer;
			display: flex;
			min-height: 2rem;
			padding: 0 1rem;

			&:hover {
				background: var(--color-table-row-selected);
			}
		}
	}

	&::-webkit-scrollbar {
		background-color: transparent;
		width: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-secondary-light);
		border: solid 6px transparent;
		border-radius: 0.5rem;
	}
}

.select_icon_arrow {
	bottom: 0.8rem;
	position: absolute;
	right: 0.75rem;
}

.select_icon_close {
	cursor: pointer;
	bottom: 0.8rem;
	position: absolute;
	right: 0.9rem;
}

.select_icon_close_white {
	cursor: pointer;
	bottom: 0.8rem;
	position: absolute;
	right: 0.9rem;
	
	path {
		fill: var(--color-white);
	}
}

.check_container {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	width: fit-content;
	padding: 5px;
}

.error_container {
	display: flex;
	flex-direction: column;
	gap: 1px;

	&_line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&_range_line {
		display: flex;
		align-items: center;
		gap: 14px;
	}

	.error_message {
		color: var(--color-error-ligth);

		&_font {
			color: var(--color-error-ligth);
			font-weight: 400!important;
			font-size: 14px;
		}
	}	
}

.form_field {
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;

	&.short {
		width: 515px;

		@include lg {
			width: 100%;
		}
	}

	.password_container {
		display: flex;
		gap: 12px;
		height: 50px;
		border: 1px solid var(--color-border-gray);
		background-color: var(--color-white);
		border-radius: 5px;
		padding: 0 16px;

		@include lg {
			width: 90%;
        }
		
		&.error {
			border-color: var(--color-error);
		}

		.button_show_pswd {
			background-color: var(--color-white);
			border: 0;
			display: flex;
			align-items: center;
			width: fit-content;
			padding: 0;
		}

		input {
			border: 0;
			padding: 0;
		}

		svg {
			width: 32px;
			
			path {
				fill: var(--color-password-icon)
			}
		}

	}

	label {
		font-weight: 700;
	}

	input {
		width: 100%;
		height: 50px;
		border: 1px solid var(--color-border-gray);
		border-radius: 5px;
		padding: 0 16px;
		box-sizing: border-box;

		&.error {
			border-color: var(--color-error);
		}
	}

	p {
		&.error_message {
			color: var(--color-error);
		}
	}
}

.password_validator {
	background-color: var(--color-white);
	position: absolute;
	right: -68%;
	border: 1px solid var(--color-purple-dark);
	border-radius: 18px;
	padding: 16px 32px;
	flex-direction: column;
	gap: 10px;
	z-index: 2;

	p {
		font-size: 15px;
	}
	
	.validator_title {
		font-weight: 600;
	}

	&.active {
		display: flex;
	}

	.validator_row {
		display: flex;
		gap: 10px;
		align-items: center;

		&.validated {
			svg {
				circle {
					fill: var(--color-secondary-light);
				}
			}
		}

		&.error {
			color: var(--color-error);
		}
	}

	.box_notch {
		position: absolute;
		width: 15px;
		height: 15px;
		left: -9px;
		z-index: 10;
		border-bottom: 1px solid var(--color-purple-dark);
		border-left: 1px solid var(--color-purple-dark);
		transform: rotate(45deg);
		background-color: var(--color-white);
	}

}

.seniority {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&_content {
		display: flex;
		gap: 8px;
		color: var(--color-purple-dark);

		&_item {
			border: 1px solid var(--color-purple-dark);
			border-radius: 5px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding: 8px;
			width: 100%;

			&:hover {
				background-color: var(--color-hovered-item);
			}

			&_label {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				label {
					cursor: pointer;
				}
			}

			&_selected {
				background-color: var(--color-purple-dark);
				color: var(--color-white);

				&:hover {
					background-color: var(--color-hovered-item);
					color: var(--color-purple-dark);

					img {
						filter: brightness(0) saturate(100%) invert(28%) sepia(21%) saturate(3532%) hue-rotate(210deg) brightness(96%) contrast(96%)
					}
				}

				img {
					filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(7481%) hue-rotate(108deg) brightness(106%) contrast(96%);
				}
			}
		}
	}
}

.radio {
	cursor: pointer;
	display: flex;
	gap: 30px;
	font-size: 16px;
	font-weight: 500;

	&_item {
		border: 2px solid var(--color-blue);
		border-radius: 40px;
		padding: 10px 24px;

		&:hover {
			background-color: var(--color-purple-dark);
			color: var(--color-white);
		}

		&_selected {
			background-color: var(--color-blue);
			color: var(--color-white);
		}
	}
}

.bar {
	display: flex;
	align-items: center;
	gap: 8px;

	.square {
		cursor: pointer;
		background: var(--color-steper-mobile);
		width: 40px;
		height: 27px;

		&_before {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		&_last {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.font_ligth {
		font-weight: 400;
	}

	&_line {
		display: flex;
        align-items: center;
        gap: 8px;
	}

	&_simple {
		--backgroundColor: #C1C1C1;
		height: 16px;
		width: 100%;
		border-radius: 20px;
		background-color: var(--backgroundColor);

		.progress {
			height: 16px;
			border-radius: 20px;
		}
	}
}