@import "../../../styles/breakpoints.scss";

.vacancy {
    width: 100%;
    padding: 48px 65px;

    .no_scroll {
        overflow: hidden;
      }

    .paddingEnd {
        padding-bottom: 80px;
    }

    hr {
        border-top: 1px solid var(--swiper-theme-color);
        border-bottom: 0;
        margin: 12px 0;
    }

    .margin_long {
        margin: 40px 0;
    }

    input {
        border-radius:  5px;
        border: 1px solid var(--color-border-gray);
        height: 50px;
        padding: 0 16px;
        box-sizing: border-box;

        &.file_input {
            display: none;
        }

        &.error {
            border-color: var(--color-error);
        }
    }

    select {
        border-radius:  5px;
        border: 1px solid var(--color-border-gray);
        height: 50px;
        padding: 0 16px;
        box-sizing: border-box;
    }

    textarea {
        resize: none;
        border: 1px solid var(--color-border-gray);
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        height: 80px;

        &.tall {
            height: 350px;
        }
    }

    button {
        &.add_info {
            background-color: var(--color-white);
            border: 0;
            padding: 1px;
            width: fit-content;
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
            color: var(--color-secondary-light);
            font-size: 16px;
            font-weight: 700;

            &.padded {
                padding-top: 32px;
            }

            &.idle {
                padding-top: 32px;
                color: var(--color-purple-dark);

                svg {
                    path {
                        fill: var(--color-purple-dark);
                    }
                }
            }

            &:hover {
                background-color: var(--color-label-hover);
            }

        }

        &.blue {
            background-color: var(--color-primary-dark);
            border-color: transparent;
            border-radius: 10px;
            color: var(--color-white);
            display: flex;
            font-size: 16px;
            font-weight: 700;
            gap: 8px;
            padding: 8px;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover {
                background-color: var(--color-secondary);
            }
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .card {
            display: flex;
            flex-direction: column;

            .title_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 34px;
            }

            .header {
                display: flex;
                align-items: center;
                gap: 16px;

                .container {
                    display: flex;
                    gap: 8px;

                    button {
                        &.add_info {
                            background-color: var(--color-white);
                            border: 0;
                            padding: 1px;
                            width: fit-content;
                            display: flex;
                            gap: 8px;
                            justify-content: center;
                            align-items: center;
                            color: var(--color-secondary-light);
                            font-size: 16px;
                            font-weight: 700;
            
                            &.padded {
                                padding-top: 32px;
                            }
            
                            &.idle {
                                padding-top: 32px;
                                color: var(--color-purple-dark);
            
                                svg {
                                    path {
                                        fill: var(--color-purple-dark);
                                    }
                                }
                            }
            
                            &:hover {
                                background-color: var(--color-label-hover);
                            }
            
                        }
            
                        &.blue {
                            background-color: var(--color-primary-dark);
                            border-color: transparent;
                            border-radius: 10px;
                            color: var(--color-white);
                            display: flex;
                            font-size: 16px;
                            font-weight: 700;
                            gap: 8px;
                            padding: 8px;
            
                            img {
                                filter: brightness(0) invert(1);
                            }
            
                            &:hover {
                                background-color: var(--color-secondary);
                            }
                        }
                    }
                }
            }

            .section {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding-top: 24px;

                ul {
                    padding: 0 24px;
                    margin: 0;

                    li {
                        margin: 10px 0;
                    }
                }
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &_label {
                font-size: 24px;
                font-weight: 600;
            }

            .detail {
                display: flex;
                align-items: center;
                gap: 8px;

                .fontwhite {
                    color: var(--color-white)
                }

                .border {
                    border-radius: 8px;
                    padding: 4px 8px;

                    &_green {
                        background-color: var(--color-notification-success);
                    }

                    &_gray {
                        background-color: var(--color-border-gray);
                    }
                }

                &_info {
                    display: flex;
                    gap: 5px;
                }
            }
        }

        .span_row {
            display: flex;
            gap: 4px;

            .span_50 {
                max-width: 50%;
                text-align: justify;
            }
        }
    }

    .purple_title {
        font-weight: 600;
        color: var(--color-purple-dark);

        &_20px {
            font-size: 20px;
        }

        &_28px {
            font-size: 28px;
        }
    }

    .black_label {
        font-weight: 600;
        width: max-content;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 40px;

        .wrapper {
            @include lg {
                padding: 32px 0;
            }

            span {
                font-size: 20px;
                font-weight: 700;
            }

            .row {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .flex_14gap{
                    display: flex;
                    gap: 14px;
                }

                &.no_gap {
                    gap: 0px;
                }

                &.padded_short {
                    padding: 4px 0;
                }

                &.top_padded {
                    padding-top: 40px;

                    &_short {
                        padding-top: 24px;
                    }
                }
            }

            .bottom_padded {
                padding-bottom: 24px;
            }
        }
    }

    .orange_bar {
        height: 30px;
        width: 5px;
        background-color: var(--color-secondary-light);
        border-radius: 2.5px
    }

    .thin_bar {
        height: 30px;
        width: 2px;
        background-color: var(--color-border-gray);
    }

    .gap40 {
        gap: 40px;
    }

    .gap32 {
        gap: 32px;
    }

    .buttons_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;

        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 32px;

            .button {
                width: max-content;
                min-width: 220px;
                padding: 10px 80px;
                border-radius: 8px;
                font-weight: 700;
                font-size: 16px;

                &_save {
                    background-color: var(--color-secondary-light);
                    border: 0;
                    color: var(--color-white);

                    &:hover {
                        background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                    }
                }

                &_cancel {
                    border: 2px solid var(--color-purple-dark);
                    background-color: var(--color-white);
                    color: var(--color-purple-dark);

                    &:hover {
                        background-color: var(--color-hovered-item);
                    }
                }
            }
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: end;

        &_item {
            width: 45%;
            gap: 8px;

            label {
                font-weight: 700;
            }

            .subtitle_left {
                font-weight: 400;
                text-align: end;
            }

            &_full {
                width: 100%;
            }
        }

        &s_line {
            display: flex;
            align-items: center;
            gap: 40px;

            span {
                font-weight: 700;
                width: 100%;
            }

            .widh45perc {
                width: 35%;
            }

            .widh80perc {
                width: 80%;
            }

            .form_column {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &_line {
                    display: flex;
                    gap: 8px;

                    input, select {
                        width: -webkit-fill-available;
                    }
                }
            }

            &_2 {
                display: flex;
                padding-bottom: 8px;
                gap: 8px;

                img {
                    cursor: pointer;
                }
            }
        }
    }

    .action_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;

        img {
            cursor: pointer;
            border-radius: 2px;
            padding: 3px;
            
            &:hover {
                border-radius: 5px;
                background-color: var(--color-btn-hover)
            }
        }

        .trash {
            &:hover {
                border-radius: 5px;
                background-color: var(--color-trash-hover);
            }
        }
    }

    .backbutton {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        width: 90px;
        color: var(--color-secondary);
        padding: 5px;
        margin-bottom: 40px;

        span {
            color: var(--color-secondary);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        }

        &:hover {
            background-color: var(--color-label-hover);
        }
    }
}

.error_message {
    color: var(--color-error-ligth);
    font-weight: 400!important;
    font-size: 14px;
    padding-bottom: 8px;
}